import { updateMeta, useI18n } from '@thisisdevelopment/akzonobel-core';
import ColorDetails from 'pages/ColorDetails';
import CookiePolicy from 'pages/CookiePolicy';
import HomePage from 'pages/Home';
import PrivacyPolicy from 'pages/PrivacyPolicy';

import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import metas from './meta';

const homeRoutes = [
  {
    path: '/',
    Component: HomePage,
    exact: false,
  },
  {
    path: '/collection/:collectionId/colour/:cccid/:uriFriendlyName',
    Component: ColorDetails,
    exact: true,
  },
  {
    path: '/hue/:hueGlobal',
    Component: () => <></>,
    exact: true,
  },
];

const policyRoutes = [
  {
    path: '/privacy-policy',
    Component: PrivacyPolicy,
    exact: true,
  },
  {
    path: '/cookie-policy',
    Component: CookiePolicy,
    exact: true,
  },
];

const policyRoutesArr = ['/privacy-policy', '/cookie-policy'];

export default function Routes() {
  const { t } = useI18n();
  const { pathname } = useLocation();

  const renderHome = !policyRoutesArr.includes(pathname);

  useEffect(() => {
    updateMeta(metas.default, { metaDescription: t('meta.description') });
  }, [t]);

  return (
    <>
      {(renderHome ? homeRoutes : policyRoutes).map(({ path, exact, Component }) => (
        <Route key={`Route_${path}`} path={path} exact={exact}>
          <Component />
        </Route>
      ))}
    </>
  );
}
