import { Anchor } from '@thisisdevelopment/akzonobel-core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterStyled = styled.footer`
  box-shadow: 0 -2px 4px 0 rgba(79, 65, 65, 0.1);
  background-color: ${({ theme }) => theme.colors.gray1};
  margin-top: 50px;
`;

export const PaymentAndSocial = styled.div`
  border-bottom: 5px solid ${({ theme }) => theme.colors.brandBlue};
`;

export const PaymentAndSocialWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const PaymentLogo = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

export const SocialMediaLink = styled(Anchor)`
  svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.colors.brandBlue};

    .hover {
      display: none;
    }
  }

  &:hover {
    svg {
      opacity: 0.8;

      .hover {
        display: block;
      }
    }
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

export const FooterLink = styled(Anchor)`
  font-size: 0.9rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray4};

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid ${({ theme }) => theme.colors.gray3};
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;

    &:not(:last-child) {
      margin-right: 3px;
      padding-right: 3px;
    }
  }
`;

export const FooterReactLink = styled(Link)`
  font-size: 0.9rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray4};

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid ${({ theme }) => theme.colors.gray3};
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;

    &:not(:last-child) {
      margin-right: 3px;
      padding-right: 3px;
    }
  }
`;
