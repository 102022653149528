import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.div`
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Colors = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  height: 40px;

  @media (max-height: 768px) {
    gap: 10px;
    height: 30px;
  }

  @media (max-width: 480px) {
    gap: 5px;
  }
`;

type ColorBlockStyledPropType = {
  bgColor: string;
};

export const shouldForwardProp = (prop: string, defaultValidatorFn: Function) =>
  !['bgColor'].includes(prop) && defaultValidatorFn(prop);

export const ColorBlockStyled = styled(Link).withConfig<ColorBlockStyledPropType>({
  shouldForwardProp,
})`
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  transition: all ease 0.2s;
  background-color: ${({ bgColor }) => bgColor};
  font-size: 0.7rem;

  &:hover {
    transform: scale(1.03);
  }

  span {
    width: 100%;
  }

  @media (max-width: 480px) {
    padding: 5px;
  }
`;
