import history from 'routes/history';
import { bffService, ecomService, config } from '@thisisdevelopment/akzonobel-core';
import nl from 'i18n/nl.json';
import fr from 'i18n/fr.json';

const getLanguage = () => {
  const languages = ['nl', 'fr'];
  const defaultLanguage = 'nl';
  const matchPath = new RegExp(`^/(${languages.join('|')})`).exec(history.location.pathname);
  return matchPath ? matchPath[1] : defaultLanguage;
};

export const getBaseName = () => {
  return `/${getLanguage()}`;
};

export const init = () => {
  const language = getLanguage();
  const ecomUrl = process.env.REACT_APP_ECOM_URL?.replace('{LANGUAGE}', language);

  config.domainCode = `${process.env.REACT_APP_DOMAIN_CODE}`;
  config.channel = `${process.env.REACT_APP_CHANNEL}`;
  config.language = language;
  config.translations = { nl, fr };
  config.cartUrl = `${ecomUrl}/cart`;
  bffService.initAxiosConfig(`${process.env.REACT_APP_BFF_URL}`);
  ecomService.initAxiosInstance(`${ecomUrl}/api`);
};
