import React from 'react';
import { HueColorPickerStyled, ColorHue } from './styled';
import hueColors from './hueColors.json';
import { ColorCollection } from 'pages/Home';

type HueColorPickerStaticProps = {
  collection?: ColorCollection;
};

const HueColorPickerStatic = ({ ...props }: HueColorPickerStaticProps) => {
  return (
    <HueColorPickerStyled>
      {hueColors.map((hueColor) => (
        <ColorHue
          to={`/collection/${props.collection?.id}/hue/${hueColor.hueName}`}
          key={hueColor.rgb}
          style={{ backgroundColor: `#${hueColor.rgb}` }}
        />
      ))}
    </HueColorPickerStyled>
  );
};

export default HueColorPickerStatic;
