import {
  Container,
  FlexCol,
  FlexRow,
  Image,
  Text,
  Wrapper,
  useI18n,
} from '@thisisdevelopment/akzonobel-core';
import AkzoNobel from 'assets/images/akzo-nobel.svg';
import Logo from 'assets/images/logo.png';
import amexLogo from 'assets/images/payment/amex.svg';
import bancontactLogo from 'assets/images/payment/bancontact.svg';
import mastercardLogo from 'assets/images/payment/mastercard3x.svg';
import visaLogo from 'assets/images/payment/visa3x.svg';
import { ReactComponent as FacebookLogo } from 'assets/images/social/facebook.svg';
import { ReactComponent as InstagramLogo } from 'assets/images/social/instagram.svg';
import { ReactComponent as PinterestLogo } from 'assets/images/social/pinterest.svg';
import { ReactComponent as YoutubeLogo } from 'assets/images/social/youtube.svg';
import React from 'react';
import { useTheme } from 'styled-components';
import {
  FooterLink,
  FooterReactLink,
  FooterStyled,
  PaymentAndSocial,
  PaymentAndSocialWrapper,
  PaymentLogo,
  SocialMediaLink,
} from './styled';

function Footer() {
  const { t } = useI18n();
  const theme = useTheme();
  const year = new Date().getFullYear();

  return (
    <FooterStyled>
      <PaymentAndSocial>
        <Container>
          <PaymentAndSocialWrapper>
            <FlexCol alignItems="center" mb="40px">
              <Text fontWeight="extrabold" color={theme.colors.gray4}>
                {t('footer.paymentOptions.title')}
              </Text>
              <FlexRow alignItems="center" mt="10px">
                <PaymentLogo src={visaLogo} alt={t('footer.paymentOptions.visa')}></PaymentLogo>
                <PaymentLogo src={amexLogo} alt={t('footer.paymentOptions.amex')}></PaymentLogo>
                <PaymentLogo
                  src={bancontactLogo}
                  alt={t('footer.paymentOptions.bancontact')}
                ></PaymentLogo>
                <PaymentLogo
                  src={mastercardLogo}
                  alt={t('footer.paymentOptions.mastercard')}
                ></PaymentLogo>
              </FlexRow>
            </FlexCol>
            <FlexCol alignItems="center" mb="40px">
              <Text fontWeight="extrabold" color={theme.colors.gray4}>
                {t('footer.social.title')}
              </Text>
              <FlexRow alignItems="center" mt="10px">
                <SocialMediaLink
                  href="https://www.facebook.com/dulux/?brand_redir=107367049350563"
                  target="_blank"
                >
                  <FacebookLogo />
                </SocialMediaLink>
                <SocialMediaLink href="https://www.instagram.com/levis_letscolour/" target="_blank">
                  <InstagramLogo />
                </SocialMediaLink>
                <SocialMediaLink href="https://www.pinterest.com/levisletscolour/" target="_blank">
                  <PinterestLogo />
                </SocialMediaLink>
                <SocialMediaLink
                  href="https://www.youtube.com/user/levisletscolour"
                  target="_blank"
                >
                  <YoutubeLogo />
                </SocialMediaLink>
              </FlexRow>
            </FlexCol>
          </PaymentAndSocialWrapper>
        </Container>
      </PaymentAndSocial>
      <Wrapper>
        <Container>
          <FlexCol alignItems="center" my="40px">
            <Image src={Logo} alt="AkzoNobel" height="40px" />
            <FlexRow mt="30px">
              <FooterReactLink to="/cookie-policy">{t('footer.links.cookies')}</FooterReactLink>
              <FooterLink className="optanon-toggle-display" href="#">
                {t('footer.links.cookieSettings')}
              </FooterLink>
              <FooterLink href="https://www.levis.info/nl/wettelijke-bepalingen" target="_blank">
                {t('footer.links.laws')}
              </FooterLink>
              <FooterReactLink to="/privacy-policy">
                {t('footer.links.privacyPolicy')}
              </FooterReactLink>
              <FooterLink href="https://www.akzonobel.com/en" target="_blank">
                {t('footer.links.otherSites')}
              </FooterLink>
            </FlexRow>
            <FlexRow mt="30px" alignItems="center">
              <Text fontSize="xs" color={theme.colors.gray4}>
                Copyright © AkzoNobel Paints {year}
              </Text>
              <Image src={AkzoNobel} alt="AkzoNobel" height="15px" ml="20px" />
            </FlexRow>
          </FlexCol>
        </Container>
      </Wrapper>
    </FooterStyled>
  );
}

export default Footer;
