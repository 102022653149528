import React from 'react';
import Routes from './routes';
import Topbar from 'components/Topbar';
import styled, { ThemeProvider } from 'styled-components';
import {
  NotificationsContextProvider,
  EcomContextProvider,
} from '@thisisdevelopment/akzonobel-core';
import theme from 'theme';
import GlobalStyle from 'theme/GlobalStyle';
import Footer from 'components/Footer';

export const AppStyled = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <NotificationsContextProvider>
        <EcomContextProvider>
          <AppStyled>
            <Topbar />
            <Routes />
            <Footer />
          </AppStyled>
        </EcomContextProvider>
      </NotificationsContextProvider>
    </ThemeProvider>
  );
}

export default App;
