import { createGlobalStyle } from 'styled-components';
import ColourSansRegularWoff from 'assets/fonts/ColourSans-Regular.woff';
import ColourSansRegularWoff2 from 'assets/fonts/ColourSans-Regular.woff2';

const GlobalStyle = createGlobalStyle`
  @font-face {
  font-family: ColourSansRegular;
  src:  local(ColourSansRegular),
        url(${ColourSansRegularWoff}) format("woff"),
        url(${ColourSansRegularWoff2}) format("woff2");
  font-weight: 400;
  font-style: normal;
}

  * {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    font-size: 16px;
    font-family: ColourSansRegular;
  }

  input, button, select, textarea {
    font-family: ColourSansRegular;
  }

  p {
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    overflow-y: scroll;
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export default GlobalStyle;
