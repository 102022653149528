import React, { useRef, useEffect, useState } from 'react';
import { Svg } from '@thisisdevelopment/akzonobel-core';
import { ColorPickerStyled, ScrollLeft, ScrollRight, Colors, ScrollArea } from './styled';
import { useMediaQuery } from 'hooks/useMediaQuery';

type PropTypes = {
  children: JSX.Element | JSX.Element[];
  columns: number;
};

type ScrollDirection = 'left' | 'right';

function ColorPicker({ children, columns }: PropTypes) {
  const isMobile: boolean = useMediaQuery();
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [hasMoreLeft, setHasMoreLeft] = useState(false);
  const [hasMoreRight, setHasMoreRight] = useState(columns > 6 || isMobile);

  useEffect(() => {
    const listener = () => {
      if (containerRef.current && scrollerRef.current) {
        const { offsetWidth } = containerRef.current;
        const { scrollLeft, scrollWidth } = scrollerRef.current;

        setHasMoreLeft(scrollLeft !== 0);
        setHasMoreRight(scrollLeft !== scrollWidth - offsetWidth);
      }
    };
    const scrollerElement = scrollerRef.current;

    scrollerElement?.addEventListener('scroll', listener);

    return () => {
      scrollerElement?.removeEventListener('scroll', listener);
    };
  }, []);

  const scrollTo = (to: ScrollDirection) => {
    if (containerRef.current && scrollerRef.current) {
      const { offsetWidth } = containerRef.current;
      const { scrollLeft, scrollWidth } = scrollerRef.current;
      const left =
        to === 'right'
          ? Math.min(scrollLeft + offsetWidth, scrollWidth)
          : Math.max(scrollLeft - offsetWidth, 0);
      scrollerRef.current?.scroll({ left });
    }
  };

  return (
    <ColorPickerStyled ref={containerRef}>
      {hasMoreLeft && (
        <ScrollLeft onClick={() => scrollTo('left')}>
          <Svg.Carret />
        </ScrollLeft>
      )}
      <ScrollArea ref={scrollerRef} shouldCenter={!hasMoreRight && !hasMoreLeft}>
        <Colors columns={columns} hasPages={false}>
          {children}
        </Colors>
      </ScrollArea>
      {hasMoreRight && (
        <ScrollRight onClick={() => scrollTo('right')}>
          <Svg.Carret />
        </ScrollRight>
      )}
    </ColorPickerStyled>
  );
}

export default ColorPicker;
