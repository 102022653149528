import React, { useEffect, useState } from 'react';
import { Svg } from '@thisisdevelopment/akzonobel-core';
import {
  ColorPickerStyled,
  ScrollLeft,
  ScrollRight,
  Colors,
  ScrollArea,
} from '../ColorPicker/styled';

type PropTypes = {
  children: JSX.Element[];
  columns: number;
  setPage: (page: number) => void;
  page: number;
  pages: number;
};

function ColorPickerPaginated({ children, columns, setPage, page, pages }: PropTypes) {
  const [hasMoreLeft, setHasMoreLeft] = useState(false);
  const [hasMoreRight, setHasMoreRight] = useState(true);

  useEffect(() => {
    setHasMoreRight(page < pages);
    setHasMoreLeft(page > 1);
  }, [page]);

  return (
    <ColorPickerStyled>
      {hasMoreLeft && (
        <ScrollLeft onClick={() => setPage(page - 1)}>
          <Svg.Carret />
        </ScrollLeft>
      )}
      <ScrollArea shouldCenter={columns < 6}>
        <Colors columns={columns} hasPages={true}>
          {children}
        </Colors>
      </ScrollArea>
      {hasMoreRight && (
        <ScrollRight onClick={() => setPage(page + 1)}>
          <Svg.Carret />
        </ScrollRight>
      )}
    </ColorPickerStyled>
  );
}

export default ColorPickerPaginated;
