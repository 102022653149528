import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  Overlay,
  StyledColorDetails,
  CloseButton,
  ProductWrapper,
  ColorPhotosWrapper,
  LevisColorTile,
  LevisWrapper,
  LevisColorCombinationWrapper,
  TabsWrapper,
} from './styled';
import {
  useColor,
  useProduct,
  useProducts,
  useArticle,
  InputSelect,
  Paragraph,
  Button,
  Svg,
  Text,
  Spacer,
  useEcom,
  Color,
  useI18n,
  ColorPhotos,
  H4,
  Product,
} from '@thisisdevelopment/akzonobel-core';
import { useParams, useRouteMatch } from 'react-router-dom';
import ColorCombinations from 'components/ColorCombinations';
import { Tab, default as Tabs } from 'components/Tabs';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useReferrer } from 'hooks/useReferrer';
import referrersConfig from 'config/referrerConfig.json';

type ColorDetailsTabs = 'Product' | 'Combinations' | 'Photos';

function ColorDetails() {
  const { t } = useI18n();
  const history = useHistory();
  const { cccid } = useParams<{ cccid: string }>();
  const { color, fetchColor } = useColor();
  const { product, fetchProduct } = useProduct();
  const { products, fetchAllProducts } = useProducts();
  const { article, fetchArticle, setArticle, isLoading: isLoadingArticle } = useArticle();
  const { addToCart, checkStock, resetStockCheck, hasStock } = useEcom();
  const [globalId, setGlobalId] = useState<string | undefined>();
  const [activeTab, setActiveTab] = useState<ColorDetailsTabs>('Product');
  const [packSize, setPackSize] = useState<string | undefined>();
  const [productsFiltered, setProductsFiltered] = useState<Product[] | undefined>();
  const hasPhotos = !!(color?.inspirationalImages && color.inspirationalImages.length > 0);
  const matchCollection = useRouteMatch<{ collectionId: string }>('/collection/:collectionId');
  const collectionId = matchCollection?.params.collectionId;
  const productHasPackSizes = product?.packSizes && product.packSizes.length > 0;
  const isMobile = useMediaQuery();
  const referrer = useReferrer();

  const tabs: Tab[] = [
    { name: 'Product', label: t('colors.product') },
    { name: 'Combinations', label: t('colors.combinations') },
    {
      name: 'Photos',
      label: t('colors.photos'),
      disabled: !hasPhotos,
    },
  ];

  useEffect(() => {
    fetchAllProducts();
  }, [fetchAllProducts]);

  useEffect(() => {
    fetchColor(cccid);
  }, [cccid, fetchColor]);

  const colorProducts = useMemo(
    () => color && products?.filter((product) => color.products?.includes(product.globalId)),
    [products, color]
  );

  useEffect(() => {
    if (colorProducts) {
      const configForReferrer = referrer && referrersConfig.find((ref) => ref.name === referrer);
      const defaultConfig = referrersConfig.find((ref) => ref.name === 'default');
      const productsforReferrer = configForReferrer
        ? colorProducts.filter((product) => configForReferrer.products.includes(product.globalId))
        : colorProducts.filter((product) => defaultConfig?.products.includes(product.globalId));
      setProductsFiltered(productsforReferrer);
    }
  }, [colorProducts, referrer]);

  useEffect(() => {
    if (productsFiltered && productsFiltered?.length === 1) {
      setPackSize(undefined);
      setArticle(undefined);
      resetStockCheck();
      setGlobalId(productsFiltered[0].globalId);
    }
  }, [productsFiltered, productHasPackSizes]);

  useEffect(() => {
    if (globalId) {
      fetchProduct({ globalId });
    }
  }, [globalId, fetchProduct, color]);

  useEffect(() => {
    if (product && product.validEcomData && color && packSize) {
      fetchArticle(product.globalId, color.colorCollectionColorId, packSize);
    } else if (product && product.validEcomData && packSize) {
      fetchArticle(product.globalId, null, packSize);
    }
  }, [packSize, product, color, fetchArticle]);

  useEffect(() => {
    if (productHasPackSizes && product?.packSizes.length === 1) {
      setPackSize(product.packSizes[0]);
    }
  }, [product, productHasPackSizes]);

  useEffect(() => {
    if (color && article) {
      checkStock(article.articleNumber, color.colorCollectionColorId);
    } else if (article) {
      checkStock(article.articleNumber, null);
    }
  }, [article, color, checkStock]);

  const addProductToCart = (color: Color) => {
    if (article) {
      addToCart(article.articleNumber, color.colorCollectionColorId, 1, referrer);
      history.push(`/collection/${collectionId}`);
    }
  };

  const onChangeProduct = (value: string) => {
    setPackSize(undefined);
    setArticle(undefined);
    resetStockCheck();
    setGlobalId(value);
  };

  const onChangePacksize = (value: string) => {
    setArticle(undefined);
    resetStockCheck();
    setPackSize(value);
  };

  return (
    <Overlay>
      {color && collectionId && productsFiltered && (
        <StyledColorDetails>
          <CloseButton to={`/collection/${collectionId}`}>
            <Svg.Close width="15px" />
          </CloseButton>
          <LevisWrapper>
            <LevisColorTile
              color={color}
              borderRadius="0 30px"
              padding="20px"
              width="300px"
              height={isMobile ? '200px' : '100%'}
            />
          </LevisWrapper>
          <TabsWrapper>
            <Tabs tabs={tabs} active={activeTab} onChange={setActiveTab}></Tabs>
          </TabsWrapper>
          <ProductWrapper isActive={activeTab === 'Product'}>
            <InputSelect
              label={t('ecom.product')}
              placeholder={t('ecom.selectProduct')}
              options={productsFiltered.map(({ name, globalId }) => ({
                label: name,
                value: globalId,
              }))}
              onChange={onChangeProduct}
              value={globalId}
            ></InputSelect>
            {product && productHasPackSizes ? (
              <Spacer mt="20px">
                <InputSelect
                  label={t('ecom.packsize')}
                  placeholder={t('ecom.selectPacksize')}
                  options={product?.packSizes.map((value) => ({ label: value, value }))}
                  onChange={onChangePacksize}
                  value={packSize}
                ></InputSelect>
              </Spacer>
            ) : (
              <Spacer height="65px" mt="20px" />
            )}
            {!isLoadingArticle && packSize ? (
              <Spacer mt="20px">
                <Paragraph margin="0 0 5px 5px">{t('ecom.price')}</Paragraph>
                <Paragraph margin="0 0 5px 5px" fontSize="lg" fontWeight="extrabold">
                  {article ? article.price.friendlyPrice : t('ecom.articleNotFound')}
                </Paragraph>
              </Spacer>
            ) : (
              <Spacer height="46px" mt="20px" />
            )}
            <Button
              width="100%"
              mt="20px"
              disabled={!hasStock}
              onClick={() => addProductToCart(color)}
            >
              <Spacer mr="5px">
                <Svg.Cart fill="white" width="20px" />
              </Spacer>
              <Text>{t('ecom.addToCart')}</Text>
            </Button>
          </ProductWrapper>
          {hasPhotos && (
            <ColorPhotosWrapper
              mb="0px"
              width="100%"
              height="160px"
              isActive={activeTab === 'Photos'}
            >
              {color.inspirationalImages && (
                <ColorPhotos inspirationalImages={color.inspirationalImages} />
              )}
            </ColorPhotosWrapper>
          )}
          {(color.schemesEnriched?.designerCombinations ||
            color.schemesEnriched?.neutralCombinations ||
            color.schemesEnriched?.tonalCombinations) && (
            <LevisColorCombinationWrapper width="100%" isActive={activeTab === 'Combinations'}>
              <H4 textAlign="center" mb="10px" mt="20px">
                {t('colors.colorCombinations')}
              </H4>
              <ColorCombinations color={color} collectionId={+collectionId} />
            </LevisColorCombinationWrapper>
          )}
        </StyledColorDetails>
      )}
    </Overlay>
  );
}

export default ColorDetails;
