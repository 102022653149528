import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorHandling from 'components/ErrorHandling';
import { init, getBaseName } from 'config';

init();

ReactDOM.render(
  <React.StrictMode>
    <ErrorHandling>
      <BrowserRouter basename={getBaseName()}>
        <App />
      </BrowserRouter>
    </ErrorHandling>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
