import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Wrapper,
  H2,
  Paragraph,
  Container,
  useColors,
  useI18n,
  Spacer,
  InputSelect,
} from '@thisisdevelopment/akzonobel-core';
import ColorPicker from 'components/ColorPicker';
import ColorCard from 'components/ColorCard';
import HueColorPickerStatic from 'components/HueColorPickerStatic';
import hueColors from 'components/HueColorPickerStatic/hueColors.json';
import colorCollections from './colorCollections.json';
import { Color } from 'types/colors';
import ColorPickerPaginated from 'components/ColorPickerPaginated';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useReferrer } from 'hooks/useReferrer';
import referrersConfig from 'config/referrerConfig.json';

export const CollectionSelectorStyled = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export type ColorCollection = {
  key: string;
  id: string;
  label: string;
  showHuePicker: boolean;
  extraColors: boolean;
};

type CollectionPositioning = {
  rows: number;
  columns: number;
  pages: number;
};

export type ScrollInto = {
  options: ScrollIntoViewOptions;
  cccid: number;
};

function HomePage() {
  const history = useHistory();
  const { t } = useI18n();
  const [extendedColors, setExtendedColors]: [Color[], Function] = useState([]);
  const [collection, setCollection] = useState<ColorCollection | undefined>();
  const [filteredCollections, setFilteredCollections] = useState<ColorCollection[] | undefined>();
  const [scrollInto, setScrollInto] = useState<ScrollInto | null>();
  const [collectionPositioning, setCollectionPositioning] = useState<CollectionPositioning | null>(
    null
  );
  const [page, setPage] = useState<number>(1);
  const { colors, fetchColors, setColors, isLoading } = useColors();
  const matchColor = useRouteMatch<{ cccid: string }>(
    '/collection/:collectionId/colour/:cccid/:uriFriendlyName'
  );
  const cccid = matchColor?.params.cccid;
  const matchHue = useRouteMatch<{ hueGlobal: string }>('/collection/:collectionId/hue/:hueGlobal');
  const hueGlobal = matchHue?.params.hueGlobal;
  const matchCollection = useRouteMatch<{ collectionId: string }>('/collection/:collectionId');
  const collectionId = matchCollection?.params.collectionId;
  const isMobile: boolean = useMediaQuery();
  const referrer = useReferrer();

  useEffect(() => {
    const configForReferrer = referrer && referrersConfig.find((ref) => ref.name === referrer);
    const collectionsForReferrer =
      configForReferrer &&
      configForReferrer.collections
        .map((id) => colorCollections.find((collection) => collection.id === id))
        .filter((collection): collection is ColorCollection => !!collection);

    if (collectionsForReferrer && collectionsForReferrer.length > 0) {
      setFilteredCollections(collectionsForReferrer);
    } else {
      setFilteredCollections(colorCollections);
    }
  }, [colorCollections, referrer]);

  useEffect(() => {
    if (collection) {
      const query = new URLSearchParams();
      query.set('colorCollectionId[]', collection.id);
      fetchColors(query.toString());
    }
  }, [history, fetchColors, collection]);

  useEffect(() => {
    if (colors && cccid) {
      const color = colors.find(({ colorCollectionColorId }) => colorCollectionColorId === +cccid);

      if (color) {
        setScrollInto({
          cccid: color?.colorCollectionColorId,
          options: { block: 'start', inline: 'nearest' },
        });
        if (color.colorWall.page > 0) {
          setPage(color.colorWall.page);
        }
      }
    } else {
      if (colors && hueGlobal) {
        const hueColor = hueColors.find(({ hueName }) => hueName === hueGlobal);
        const color =
          hueColor &&
          colors.find(({ colorCollectionColorId }) => colorCollectionColorId === hueColor?.cccid);

        if (color) {
          setScrollInto({
            cccid: color?.colorCollectionColorId,
            options: { block: 'end', inline: 'start' },
          });
        }
      }
    }
  }, [colors, hueGlobal, setScrollInto, cccid]);

  useEffect(() => {
    if (collection && colors) {
      const rows = Math.max(...colors.map((color) => color.colorWall.row), 0);
      const columns = Math.max(...colors.map((color) => color.colorWall.column), 0);
      const pages = Math.max(...colors.map((color) => color.colorWall.page), 1);
      setCollectionPositioning({
        columns: columns,
        rows: rows,
        pages: pages,
      });
    }
  }, [colors]);

  useEffect(() => {
    if (collectionPositioning) {
      const colorsExtended = [];

      if (collectionPositioning.columns === 0 && collectionPositioning.rows === 0) {
        setExtendedColors(colors);
      } else {
        for (let x = 0; x < collectionPositioning.rows; x++) {
          for (let y = 0; y < collectionPositioning.columns; y++) {
            const colorForPosition = colors.find((color) => {
              return collectionPositioning.pages > 1
                ? color.colorWall?.row === x + 1 &&
                    color.colorWall.column === y + 1 &&
                    color.colorWall.page === page
                : color.colorWall?.row === x + 1 && color.colorWall.column === y + 1;
            });
            if (colorForPosition) {
              colorsExtended.push(colorForPosition);
            } else {
              colorsExtended.push({
                colorCollectionColorId: 0,
                colorCollections: [{ colorWall: { column: y + 1, row: x + 1 } }],
              });
            }
          }
        }
        setExtendedColors(colorsExtended);
      }
    }
  }, [collectionPositioning, page]);

  const onChangeCollection = (value: string) => {
    if (collection) {
      const selectedCollection = colorCollections?.find((collection) => collection.id === value);
      setColors([]);
      setScrollInto(null);
      setCollection(selectedCollection);
      setPage(1);
      if (selectedCollection) {
        history.push(`/collection/${selectedCollection.id}`);
      }
    }
  };

  const mappedCollection = useMemo(() => {
    if (filteredCollections) {
      const initialCollection = filteredCollections?.find(
        (collection) => collection.id === collectionId
      );

      setCollection(initialCollection ? initialCollection : filteredCollections[0]);
      return filteredCollections.map((collection) => {
        return {
          key: collection.key,
          label: collection.label,
          value: collection.id,
        };
      });
    }
  }, [filteredCollections, collectionId]);

  return (
    <>
      {extendedColors && collection && (
        <Wrapper as="main">
          <Container>
            <H2 textAlign="center" mt="20px">
              {t('colors.pickYourColor')}
            </H2>

            {referrer?.toLowerCase() === 'ikea' ? (
              <>
                <Paragraph mt="10px" mb="10px">
                  {t('colors.ikeaLabelLine1')}
                </Paragraph>
                <Paragraph mt="10px" mb="10px">
                  {t('colors.ikeaLabelLine2')}
                </Paragraph>
              </>
            ) : (
              <Paragraph textAlign="center" mt="10px" mb="10px">
                {t('colors.pickYourColorSub')}
              </Paragraph>
            )}

            {mappedCollection && (
              <CollectionSelectorStyled>
                <Paragraph textAlign="center" mb="5px" fontSize="sm">
                  {t('collection.pickYourCollection')}
                </Paragraph>
                <InputSelect
                  options={mappedCollection}
                  placeholder="Select a Collection"
                  value={collection?.id}
                  onChange={(value) => onChangeCollection(value)}
                />
              </CollectionSelectorStyled>
            )}

            {collection.showHuePicker && (
              <Wrapper mt="20px">
                <HueColorPickerStatic collection={collection} />
              </Wrapper>
            )}
            <Wrapper mt="50px">
              {!isLoading && collectionPositioning?.pages === 1 && (
                <ColorPicker
                  columns={collectionPositioning.columns === 0 ? 6 : collectionPositioning.columns}
                >
                  {extendedColors.map((color, index) =>
                    color.colorCollectionColorId === 0 ? (
                      <Spacer key={`${color.colorCollectionColorId}-${index}`}></Spacer>
                    ) : (
                      <ColorCard
                        key={`${color.colorCollectionColorId}-${index}`}
                        color={color}
                        collectionId={+collection?.id}
                        scrollInto={
                          color.colorCollectionColorId === scrollInto?.cccid ? scrollInto : null
                        }
                      />
                    )
                  )}
                </ColorPicker>
              )}
              {!isLoading && collectionPositioning && collectionPositioning.pages > 1 && (
                <ColorPickerPaginated
                  columns={isMobile ? 1 : collectionPositioning.columns}
                  page={page}
                  pages={collectionPositioning.pages}
                  setPage={setPage}
                >
                  {extendedColors.map((color, index) =>
                    color.colorCollectionColorId === 0 ? (
                      <Spacer key={`${color.colorCollectionColorId}-${index}`}></Spacer>
                    ) : (
                      <ColorCard
                        key={`${color.colorCollectionColorId}-${index}`}
                        color={color}
                        collectionId={+collection?.id}
                        scrollInto={
                          color.colorCollectionColorId === scrollInto?.cccid ? scrollInto : null
                        }
                      />
                    )
                  )}
                </ColorPickerPaginated>
              )}
            </Wrapper>
          </Container>
        </Wrapper>
      )}
    </>
  );
}

export default HomePage;
