import React from 'react';
import { Title, Colors, ColorBlockStyled } from './styled';
import {
  Color,
  FlexCol,
  Spacer,
  getColorNameByBgColorHex,
  Text,
  useI18n,
} from '@thisisdevelopment/akzonobel-core';

type PropTypes = {
  color: Color;
  collectionId: number;
};

type ColorCombinationPropTypes = {
  color: Color;
  title: string;
  scheme: Color[];
  collectionId: number;
};

function ColorBlock({ color, collectionId }: { color: Color; collectionId: number }) {
  return (
    <ColorBlockStyled
      to={`/collection/${collectionId}/colour/${color.colorCollectionColorId}/${color.colorCollectionColorId}`}
      bgColor={`#${color.rgb}`}
    >
      <Text color={getColorNameByBgColorHex(color.rgb)}>{color.defaultColorName}</Text>
    </ColorBlockStyled>
  );
}

function ColorCombination({ color, title, scheme, collectionId }: ColorCombinationPropTypes) {
  return (
    <FlexCol mb="20px">
      <Title>{title}</Title>
      <Colors>
        <ColorBlock color={scheme[0]} collectionId={collectionId} />
        <ColorBlock color={color} collectionId={collectionId} />
        <ColorBlock color={scheme[1]} collectionId={collectionId} />
      </Colors>
    </FlexCol>
  );
}

function ColorCombinations({ color, collectionId }: PropTypes) {
  const { t } = useI18n();

  return (
    <Spacer width="100%">
      {color.schemesEnriched?.tonalCombinations && (
        <ColorCombination
          title={t('colors.tonalCombinations')}
          color={color}
          collectionId={collectionId}
          scheme={color.schemesEnriched.tonalCombinations}
        />
      )}
      {color.schemesEnriched?.neutralCombinations && (
        <ColorCombination
          title={t('colors.combinationWithNeutrals')}
          color={color}
          collectionId={collectionId}
          scheme={color.schemesEnriched.neutralCombinations}
        />
      )}
      {color.schemesEnriched?.designerCombinations && (
        <ColorCombination
          title={t('colors.designersChoice')}
          color={color}
          collectionId={collectionId}
          scheme={color.schemesEnriched.designerCombinations}
        />
      )}
    </Spacer>
  );
}

export default ColorCombinations;
