import { Theme, ThemeColors, defaultTheme } from '@thisisdevelopment/akzonobel-core';
import { css } from 'styled-components';

interface ThemeColorsExtended extends ThemeColors {
  brandBlue: string;
}

const colors: ThemeColorsExtended = {
  ...defaultTheme.colors,
  brandBlue: '#012169',
};

export interface ThemeExtended extends Theme {
  colors: ThemeColorsExtended;
}

const theme: ThemeExtended = {
  ...defaultTheme,
  colors,
  cart: {
    color: colors.brandBlue,
    hoverColor: colors.gray3,
    badgeColor: colors.gray3,
  },
  button: {
    base: css`
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      &:hover&:not(:disabled) {
        opacity: 0.9;
        cursor: pointer;
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray4};
      }
    `,
    colorPrimary: css`
      background-color: ${({ theme }) => theme.colors.brandBlue};
      color: ${({ theme }) => theme.colors.white};
    `,
    colorSecondary: css`
      background-color: ${({ theme }) => theme.colors.brandBlue};
      color: ${({ theme }) => theme.colors.white};
    `,
    colorTertiary: css`
      background-color: ${({ theme }) => theme.colors.brandBlue};
      color: ${({ theme }) => theme.colors.white};
    `,
    sizeSmall: css`
      font-size: 0.8rem;
      padding: 8px;
    `,
    sizeMedium: css`
      font-size: 1.1rem;
      padding: 12px;
    `,
    sizeLarge: css`
      font-size: 1.1rem;
      padding: 20px;
    `,
  },
  linkButton: {
    base: css`
      background: transparent;
      border: none;
      padding: 0;
    `,
    colorPrimary: css`
      color: ${({ theme }) => theme.colors.gray5};

      &:hover:not(:disabled) {
        text-decoration: underline;
        cursor: pointer;
      }
    `,
    colorSecondary: css`
      ${(props) => props.theme.mixins?.underlineHover(colors.gray5)};
    `,
    colorTertiary: css`
      ${(props) => props.theme.mixins?.underlineHover(colors.gray5)};
    `,
  },
  inputSelect: {
    select: css`
      padding: 10px;
      background-color: ${({ theme }) => theme.colors.gray2};
      border-radius: 6px;
      font-size: inherit;
      font-family: ColourSansRegular;
      width: 100%;
      min-width: 15rem;
      appearance: button;
    `,
    label: css`
      color: ${({ theme }) => theme.colors.black};
      font-family: ColourSansRegular;
      margin-bottom: 5px;
      margin-left: 5px;
    `,
    error: css`
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.gray1};
      margin-top: 2px;
    `,
  },
};

export default theme;
