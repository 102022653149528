import React, { useRef, useEffect } from 'react';
import { Color } from '@thisisdevelopment/akzonobel-core';
import { getColorNameByBgColorHex } from '@thisisdevelopment/akzonobel-core';
import { ColorCardStyled } from './styled';
import { StyledSpacingPropType } from '@thisisdevelopment/akzonobel-core';
import { ScrollInto } from 'pages/Home';

type PropTypes = {
  color: Color;
  scrollInto: ScrollInto | null;
  collectionId: number;
};

function ColorCard({ color, scrollInto, collectionId }: PropTypes & StyledSpacingPropType) {
  const textColor = getColorNameByBgColorHex(color.rgb);
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (scrollInto && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', ...scrollInto.options });
    }
  }, [scrollInto, ref]);

  return (
    <ColorCardStyled
      ref={ref}
      to={`/collection/${collectionId}/colour/${color.colorCollectionColorId}/${color.uriFriendlyName}`}
      bgColor={`#${color.rgb}`}
      textColor={textColor}
      key={color.colorCollectionColorId}
    >
      {color.defaultColorName}
    </ColorCardStyled>
  );
}

export default ColorCard;
