import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

type PropType = {
  children: JSX.Element | JSX.Element[];
};

function ErrorHandling({ children }: PropType) {
  if (process.env.NODE_ENV !== 'production') {
    return <>{children}</>;
  }

  Bugsnag.start({
    apiKey: '5688ca96293a5f2f2b213c62331b756b',
    plugins: [new BugsnagPluginReact()],
  });
  // eslint-disable-next-line
  const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

  return <ErrorBoundary>{children}</ErrorBoundary>;
}

export default ErrorHandling;
