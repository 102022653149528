import styled from 'styled-components';
import { ColorCardStyled } from '../ColorCard/styled';

export const ColorPickerStyled = styled.div`
  position: relative;
  min-height: 500px;

  @media (max-width: 768px) {
    min-height: unset;
  }
`;

type ScrollAreaPropType = {
  shouldCenter: boolean;
};

export const ScrollArea = styled.div<ScrollAreaPropType>`
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;

  @media (min-width: 769px) {
    ${({ shouldCenter }) =>
      shouldCenter &&
      `
      display: flex;
      justify-content: center;
  `}
  }
`;

type ColorsPropType = {
  columns: number;
  hasPages: boolean;
};

export const Colors = styled.div<ColorsPropType>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 204px);
  grid-auto-rows: 100px;
  gap: 5px;
  padding: 0 3px;
  margin-left: -2px;

  ${ColorCardStyled} {
    scroll-snap-align: end;
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(${({ columns }) => columns}, 195px);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(
      ${({ columns }) => columns},
      calc(100vw - ${({ hasPages }) => (hasPages ? '30px' : '70px')})
    );
    grid-auto-rows: 50px;
    gap: 2px;

    ${ColorCardStyled} {
      scroll-snap-align: center;
    }
  }
`;

export const ScrollLeft = styled.button`
  position: fixed;
  outline: none;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 30%;
  width: 120px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.gray1};
    width: 60px;
    transform: rotate(90deg);
  }

  @media (max-width: 1024px) {
    width: 60px;
    height: 120px;

    svg {
      width: 30px;
    }
  }

  @media (max-width: 480px) {
    width: 30px;

    svg {
      width: 20px;
    }
  }
`;

export const ScrollRight = styled(ScrollLeft)`
  left: unset;
  right: 0;

  svg {
    transform: rotate(-90deg);
  }
`;

export const SectionTitle = styled.h5`
  position: sticky;
  top: 0;
  left: 0;
  margin: 15px 0 5px 0;
`;
