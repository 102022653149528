import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'assets/images/logo.png';
import AkzoNobel from 'assets/images/akzo-nobel.svg';
import IkeaPlainTextLogo from 'assets/images/ikea-plaintextlogo.svg';
import {
  Container,
  FlexRow,
  Button,
  bffService as api,
  config,
} from '@thisisdevelopment/akzonobel-core';
import {
  TopbarStyled,
  TopbarFixed,
  Brand,
  GrayStripe,
  AkzoLogo,
  LevisLogo,
  ReferrerLogo,
  Menu,
  LanguageSwitcher,
} from './styled';
import Cart from 'components/Cart';
import { useReferrer } from 'hooks/useReferrer';

function Topbar() {
  const shouldBeCompact = () => window.scrollY >= 100;
  const [compact, setCompact] = useState<boolean>(shouldBeCompact());
  const onScroll = useCallback(() => {
    setCompact(shouldBeCompact());
  }, []);
  const referrer = useReferrer();

  useEffect(() => {
    window.addEventListener('scroll', onScroll, false);
    return () => window.removeEventListener('scroll', onScroll, false);
  }, [onScroll]);

  const changeLanguage = (language: string) => {
    api.deleteTokenLocalStore();
    window.location.href = `/${language}`;
  };

  const getReferrerLogo = () => {
    switch (referrer) {
      case 'hubo':
        return (
          <ReferrerLogo
            src="https://dkw4u37l7anpl.cloudfront.net/public/Mpoint2/akzonobel/HuboLogo.png"
            alt="Hubo"
          />
        );
      case 'brico':
        return (
          <ReferrerLogo
            src="https://dkw4u37l7anpl.cloudfront.net/public/Mpoint2/akzonobel/Brico.png"
            alt="Brico"
          />
        );
      case 'gamma':
        return (
          <ReferrerLogo
            src="https://dkw4u37l7anpl.cloudfront.net/public/Mpoint2/akzonobel/Gamma_logo.png"
            alt="Gamma"
          />
        );
      case 'mrbricolage':
        return (
          <ReferrerLogo
            src="https://dkw4u37l7anpl.cloudfront.net/public/Mpoint2/akzonobel/Mr-bricolage-logo.png"
            alt="Mr. Bricolage"
          />
        );
      case 'ikea':
        return <ReferrerLogo src={IkeaPlainTextLogo} alt="Ikea" />;
    }
  };

  return (
    <TopbarStyled>
      <TopbarFixed>
        <Brand compact={compact}>
          <Container height="100%">
            <GrayStripe>
              <Link to="/">
                <LevisLogo src={Logo} alt="AkzoNobel" />
              </Link>
              {referrer && getReferrerLogo()}
              <LanguageSwitcher>
                <Button
                  variant="link"
                  onClick={() => changeLanguage('nl')}
                  disabled={config.language === 'nl'}
                >
                  NL
                </Button>
                <Button
                  variant="link"
                  onClick={() => changeLanguage('fr')}
                  disabled={config.language === 'fr'}
                >
                  FR
                </Button>
              </LanguageSwitcher>
              <AkzoLogo src={AkzoNobel} alt="AkzoNobel" />
            </GrayStripe>
          </Container>
        </Brand>
        <Menu>
          <Container height="100%">
            <FlexRow height="100%" justifyContent="flex-end" alignItems="center">
              <Cart />
            </FlexRow>
          </Container>
        </Menu>
      </TopbarFixed>
    </TopbarStyled>
  );
}

export default Topbar;
