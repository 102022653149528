import styled, { css } from 'styled-components';

export const TopbarStyled = styled.header`
  height: 106px;
`;

export const TopbarFixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 20;
  box-shadow: 0 2px 4px 0 rgba(79, 65, 65, 0.1);
`;

type BrandPropType = {
  compact: boolean;
};

export const LevisLogo = styled.img`
  position: absolute;
  left: 15px;
  top: 10px;
  height: 70px;
  transition: all ease 0.3s;
`;

export const ReferrerLogo = styled.img`
  position: absolute;
  left: 160px;
  top: 40px;
  height: 50px;
  object-fit: contain;
  transition: all ease 0.3s;
`;

export const AkzoLogo = styled.img`
  width: 84px;
  transition: all ease 0.3s;
`;

export const LanguageSwitcher = styled.div`
  margin-right: 10px;
  font-size: 0.8rem;

  button {
    &:disabled {
      font-weight: bold;
    }

    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.gray5};
      padding-right: 5px;
      margin-right: 5px;
    }
  }
`;

export const Brand = styled.div<BrandPropType>`
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 20%, rgba(238, 238, 238, 1) 100%);
  border-top: 5px solid ${({ theme }) => theme.colors.brandBlue};
  height: 36px;
  transition: all ease 0.3s;

  ${({ compact }) =>
    compact &&
    css`
      height: 0;
      padding: 0;

      ${AkzoLogo} {
        display: none;
      }

      ${LanguageSwitcher} {
        display: none;
      }

      ${LevisLogo} {
        height: 50px;
      }
      ${ReferrerLogo} {
        left: 120px;
        top: 35px;
        height: 25px;
      }

      @media (max-width: 480px) {
        ${LevisLogo} {
          height: 40px;
        }
        ${ReferrerLogo} {
          top: 30px;
          left: 100px;
          height: 20px;
        }
      }
    `}
`;

export const GrayStripe = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Menu = styled.nav`
  height: 70px;

  @media (max-width: 480px) {
    height: 60px;
  }
`;
