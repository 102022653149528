import { useState, useEffect } from 'react';

export const useMediaQuery = () => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const isMobile: boolean = screenWidth <= 768;

  const handleWindowSizeChange = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isMobile;
};
