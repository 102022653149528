import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shouldForwardProp } from '@thisisdevelopment/akzonobel-core';

type PropTypes = {
  textColor: string;
  bgColor: string;
};

const LinkStyled = styled(Link)
  .withConfig<PropTypes>({
    shouldForwardProp,
  })
  .attrs((props: PropTypes) => ({
    style: {
      backgroundColor: props.bgColor,
      color: props.textColor,
    },
  }))``;

export const ColorCardStyled = styled(LinkStyled)`
  position: relative;
  padding: 10px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all ease 0.2s;
  font-size: 0.8rem;
  ${(props) => props.theme?.animations?.slideUpFadeIn}
  text-decoration: none;

  svg {
    display: none;
  }

  @media (min-width: 769px) {
    &:hover {
      box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.1);
      z-index: 9;

      svg {
        display: block;
      }
    }
  }
`;
