import React from 'react';
import styled, { css } from 'styled-components';
import { List, ListItem } from '@thisisdevelopment/akzonobel-core';

export type Tab = {
  name: string;
  label: string;
  disabled?: boolean;
};

type PropTypes = {
  tabs?: Tab[];
  active?: string;
  onChange?: Function;
};

type StyledPropType = {
  isActive: boolean;
  disabled: boolean;
};

export const TabsStyled = styled(List)`
  display: flex;
  margin-bottom: 30px;
`;

export const TabItem = styled(ListItem)<StyledPropType>`
  flex: 1;
  text-align: center;
  padding: 5px;
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 0.9rem;
  transition: color ease 0.5s;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.gray4};
      border-bottom: 3px solid ${({ theme }) => theme.colors.gray3};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray2};
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    `}
`;

function Tabs({ tabs, active, onChange }: PropTypes) {
  return (
    <TabsStyled>
      {tabs?.map(({ name, label, disabled = false }) => (
        <TabItem
          key={name}
          isActive={active === name}
          disabled={disabled}
          onClick={() => !disabled && onChange?.(name)}
        >
          {label}
        </TabItem>
      ))}
    </TabsStyled>
  );
}

export default Tabs;
