import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Wrapper, ColorTile } from '@thisisdevelopment/akzonobel-core';

type PropType = {
  isActive: boolean;
};

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledColorDetails = styled.div`
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 50px;
  position: relative;
  max-width: 768px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${(props) => props.theme?.animations?.slideUpFadeIn}

  @media (max-width: 768px), (max-height: 768px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: unset;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: unset;
    border-radius: unset;
    padding: 30px;
    overflow-y: scroll;
  }
`;

export const CloseButton = styled(Link)`
  position: absolute;
  top: 20px;
  right: 20px;

  @media (max-width: 768px), (max-height: 768px) {
    top: 10px;
    right: 10px;
  }
`;

export const LevisWrapper = styled(Wrapper)`
  flex: 1;
  position: relative;

  @media (max-width: 768px), (max-height: 768px) {
    flex: unset;
  }
`;

export const LevisColorTile = styled(ColorTile)`
  width: 100%;
`;

export const ProductWrapper = styled.div<PropType>`
  flex: 1;
  margin-left: 40px;

  @media (max-width: 768px), (max-height: 768px) {
    margin-left: unset;

    ${({ isActive }) =>
      !isActive &&
      css`
        display: none;
      `}
  }
`;

export const TabsWrapper = styled(Wrapper)`
  display: none;
  margin-top: 30px;

  @media (max-width: 768px), (max-height: 768px) {
    display: block;
  }
`;

export const ColorPhotosWrapper = styled(Wrapper)<PropType>`
  margin-top: 40px;

  img {
    height: 150px;
  }

  @media (max-width: 768px), (max-height: 768px) {
    flex: 1;
    margin-top: unset;

    ${({ isActive }) =>
      !isActive &&
      css`
        display: none;
      `}
  }
`;

export const LevisColorCombinationWrapper = styled(Wrapper)<PropType>`
  margin-top: 20px;

  @media (max-width: 768px), (max-height: 768px) {
    flex: 1;
    margin-top: unset;

    h4 {
      display: none;
    }

    ${({ isActive }) =>
      !isActive &&
      css`
        display: none;
      `}
  }
`;
