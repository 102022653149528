import { useState, useEffect } from 'react';
import { useQuery } from '@thisisdevelopment/akzonobel-core';
import Cookies from 'universal-cookie';

export const useReferrer = () => {
  const query = useQuery();
  const cookies = new Cookies();
  const [referrer, setReferrer] = useState<string | undefined>(cookies.get('referrer'));

  useEffect(() => {
    if (!referrer || query.get('referrer') || query.get('utm_campaign')) {
      const newReferrer = query.get('referrer') || query.get('utm_campaign') || undefined;
      if (newReferrer) {
        cookies.set('referrer', newReferrer.toLowerCase(), {
          path: '/',
          maxAge: 60 * 60 * 24 * 7,
        }); // 7 days
        setReferrer(newReferrer);
      }
    }
  }, [referrer]);

  return referrer;
};
